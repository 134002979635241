.tl-add-task {
  font-size: var(--tl-spacing-md);
  border-radius: var(--tl-spacing-xs);
  .tl-add-task__text {
    color: var(--tl-theme-base);
    display: flex;
    align-items: center;
    gap: var(--tl-spacing-s);
    font-weight: 500;
    padding-left: var(--tl-spacing-xs);
    padding-right: var(--tl-spacing-xs);
    font-family: "Inter Display";
  }
  cursor: pointer;
  &:hover {
    background-color: var(--tl-theme-light-5);
  }

  &.tl-add-task--mobile {
    padding: var(--tl-spacing-md) var(--tl-spacing-xl);
    .fills {
      fill: var(--tl-neutral-white) !important;
    }
    .stroke-shape {
      stroke: var(--tl-neutral-white) !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .tl-add-task {
    display: flex;
    justify-content: center;
    background-color: var(--tl-theme-base);
    text-align: center;
    border-radius: var(--tl-spacing-xs);
    margin-top: var(--tl-spacing-l);
    position: sticky;
    bottom: 10px;
    // padding: var(--tl-spacing-md) var(--tl-spacing-xl);
    padding: var(--tl-spacing-xs);
    .tl-add-task__text {
      color: var(--tl-neutral-white);
      padding: 0;
    }
  }
}
