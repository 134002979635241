.tl-task__date {
  .react-datepicker-wrapper {
    width: 100%;
    .tl-task__date-input-container {
      display: flex;
      gap: var(--tl-spacing-xs);
      padding: var(--tl-spacing-2xs) 0;
      align-items: center;
    }
    .tl-task__date-input {
      border-bottom: 1px solid var(--tl-theme-border);
      font-size: 1rem;
      min-height: 2.375rem;
      cursor: pointer;

      &:read-only {
        color: var(--tl-text-light-2);
      }
    }
  }
}
