.tl-today {
  .tl-today__heading {
    background-color: var(--tl-theme-bg);
    padding: var(--tl-spacing-s) var(--tl-spacing-md);
    color: var(--tl-text-base);
    font-weight: 600;
    border-radius: var(--tl-spacing-xs);
    display: flex;
    align-items: center;
    gap: var(--tl-spacing-xs);
    font-size: 1.125rem;
    .tl-today__arrow {
      position: relative;
      top: 0.25rem;
    }
  }
  .tl-today__content {
    margin-bottom: var(--tl-spacing-l);
    .tl-today__count {
      border-radius: 6px;
      padding: var(--tl-spacing-2xs) 0.375rem;
      font-size: 0.875rem;
      font-weight: 600;
      &.completed {
        background-color: #edffea;
        color: #31b71b;
      }
      &.pending {
        background-color: #ffede5;
        color: #eb6025;
      }
    }
  }
}
