.tl__search {
  border-radius: var(--tl-spacing-xs);

  input {
    font-size: 1rem;
  }

  .tl__search-container {
    padding: var(--tl-spacing-s);
    display: flex;
    gap: var(--tl-spacing-s);
    align-items: center;
  }
}

.tl__search--mobile {
  margin-bottom: 1.5rem;
}

.tl__search--focused {
  box-shadow: 0 0 0 3px #e5f0ff;
  border-radius: var(--tl-spacing-xs);
}
