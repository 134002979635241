.tl-hero {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  .tl-hero__hero-content {
    .tl-hero__text-content {
      display: flex;
      flex-direction: column;
      gap: var(--tl-spacing-xl);
      padding-bottom: 3rem;
      .tl-hero__hero-title {
        margin: 0 auto;
        text-align: center;
        font-size: 3rem;
        line-height: 1.1;
        font-size: 2.75rem;
        color: var(--tl-text-base);
        font-weight: 600;
        font-family: "Inter Display";
        @media (max-width: 64em) {
          font-size: 2.5rem;
        }
      }
      .tl-hero__hero-title,
      .tl-hero__hero-text {
        width: 50%;
        margin: 0 auto;
      }
      .tl-hero__hero-text {
        font-size: 1.125rem;
        text-align: center;
        width: 30%;
      }
      .tl-hero__hero-buttons {
        display: flex;
        gap: var(--tl-spacing-md);
        width: 100%;
        margin: 0 auto;
        width: 50%;
        justify-content: center;

        @media (max-width: 64em) {
          width: 95%;
        }
      }

      @media (max-width: 64em) {
        .tl-hero__hero-title,
        .tl-hero__hero-text {
          width: 95%;
        }
      }
    }
    .tl-hero__hero-content-img-wrapper {
      width: 85%;
      margin: 0 auto;
      text-align: center;
      .tl-hero__hero-content-img {
        width: 100%;
        height: 600px;
        object-fit: cover;
        object-position: top;
        border-radius: 16px 16px 0 0;
      }

      @media (max-width: 64em) {
        width: 100%;
      }
    }
  }
}
