/* Below 1000px */
@media (max-width: 62.5em) {

  .hero-buttons .btn-login,
  .hero-buttons .btn-demo-login {
    width: 25%;
  }

  .hero-title,
  .hero-secondary {
    width: 50%;
  }
}

/* Below 500px */
@media (max-width: 43.75em) {

  .hero-title,
  .hero-secondary {
    width: 90%;
  }

  .hero-title {
    font-size: 2.25rem;
    letter-spacing: -2px;
  }

  .hero-secondary {
    font-size: 1.var(--tl-spacing-md);
  }

  .hero-buttons .btn-login,
  .hero-buttons .btn-demo-login {
    width: 50%;
  }

  .form-background {
    width: 90%;
    margin: 0 auto;
  }

  .logo {
    width: 40%;
  }

  .navbar {
    padding: 0.7rem;
  }

  .dashboard-wrapper {
    padding: var(--tl-spacing-md);
  }

  .modal-open .dashboard-wrapper {
    padding: 0 0.5rem;
  }

  .dashboard-header {
    padding: 0.8rem var(--tl-spacing-md);
  }

  .features-section {
    margin-top: 4rem;
  }

  .feature {
    text-align: center;
  }

  .feature:nth-child(even) {
    flex-direction: column-reverse;
  }

  .task-label {
    display: none;
  }

  .task-modal .task-label {
    display: block;
    width: 30%;
  }

  .hero-image .img-fluid {
    max-width: 100%;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1140px;
  }
}

@media (max-width: 768px) {
  .dashboard {
    margin-left: 0 !important;
  }
}

@media (max-width: 770px) {
  .stat-container {
    flex-direction: column;
  }
}

@media (min-width: 768px) {
  .stats-container .col-lg-3 {
    width: 32%;
  }

}