.tl-sidebar {
  .sidebar-desktop,
  .sidebar-mobile {
    display: flex;
    flex-direction: column;
    gap: var(--tl-spacing-xl);
  }

  .sidebar-desktop {
    position: fixed;
    top: 0;
    height: 100%;
    padding-top: var(--tl-spacing-2xl);
    padding-bottom: var(--tl-spacing-2xl);
    width: 15rem;
    .sidebar-link-active,
    .tl-navigation__navigation-item,
    .logout,
    .tl-add-task {
      &:hover,
      &.sidebar-link-active {
        background-color: var(--tl-neutral-white);
        cursor: pointer;
        border-radius: var(--tl-spacing-xs);
      }
    }
  }

  .tl-navigation__nav-link,
  .logout-text,
  .add-task__text {
    display: flex;
    align-items: center;
    gap: var(--tl-spacing-s);
  }

  /* Logout */
  .logout {
    margin-top: auto;
  }

  .sidebar-mobile {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    background-color: #fff;
    height: 100%;
    width: 0px;
    border-radius: var(--tl-spacing-xl) 0px 0px var(--tl-spacing-xl);
    box-shadow: -8px 0px 16px 0px #e5e7eb;
    display: flex;
    flex-direction: column;
    gap: var(--tl-spacing-l);
    transition: width 0.3s ease;

    .sidebar-mobile-profile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--tl-spacing-xs);
      padding: var(--tl-spacing-md);
      border-bottom: 1px solid var(--tl-theme-border);
    }

    &.hidden {
      width: 0;
      left: -360px;
      visibility: hidden;
    }

    &.visible {
      width: 360px;
      visibility: visible;
    }
  }

  @media screen and (max-width: 1024px) {
    .tl-navigation__navigation-item,
    .logout,
    .tl-add-task {
      &:hover,
      &.sidebar-link-active {
        background-color: var(--tl-theme-bg);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .logout {
      padding: 0 var(--tl-spacing-md);
    }

    .tl-add-task {
      display: none;
    }
  }
}
