.add-new-task-link {
  font-size: var(--tl-spacing-md);
}

// @media screen and (max-width: 1024px) {
//   .tl-tasks__container {
//     max-height: 300px;
//     overflow: auto;
//   }
// }
