.tl-feature {
  display: flex;
  justify-content: space-between;
  gap: 6rem;
  @media (max-width: 64em) {
    flex-direction: column;
  }
  .tl-feature__feature-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    gap: var(--tl-spacing-xl);
    .tl-feature__feature-title {
      font-size: 1.75rem;
      font-weight: 600;
      line-height: 1.2;
    }
    .tl-btn {
      width: 30%;
      text-align: center;
    }
    .tl-feature__feature-btn {
      @media (max-width: 64em) {
        margin: 0 auto;
      }
    }
  }
  .tl-feature__feature-img {
    width: 50%;
  }

  .tl-feature__feature-text,
  .tl-feature__feature-img {
    @media (max-width: 64em) {
      width: 95%;
      text-align: center;
      margin: 0 auto;
    }
  }
  .feature-1 {
    order: 1;
  }
}
