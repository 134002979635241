.tl-task-item {
  display: flex;
  gap: var(--tl-spacing-s);
  border-bottom: var(--tl-spacing-2xs);
  margin-bottom: var(--tl-spacing-xs);
  align-items: baseline;
  &:hover {
    cursor: pointer;
  }

  .tl-task-item__edit-icon,
  .tl-task-item__delete-icon {
    margin-top: var(--tl-spacing-xs);
  }

  .tl-task-item__checkbox,
  .tl-task-item__task {
    padding-top: var(--tl-spacing-md);
    padding-bottom: var(--tl-spacing-s);
  }

  .tl-task-item__checkbox {
    position: relative;
    top: 4px;
  }

  .tl-task-item__checkbox-input {
    width: var(--tl-spacing-md);
    height: var(--tl-spacing-md);
    appearance: none;
    border-radius: 50%;
    border: 2px solid var(--tl-theme-border);
  }
  .tl-task-item__info-options {
    display: flex;
    gap: var(--tl-spacing-xl);
    align-items: center;
    .tl-task-item__options {
      display: flex;
      gap: 1.125rem;
      .tl-task-item__edit,
      .tl-task-item__delete {
        border-radius: 0.375rem;
        padding: 0.375rem;
      }
      .tl-task-item__edit {
        &:hover {
          background-color: var(--tl-theme-light-5);
          .stroke-shape-edit {
            stroke: var(--tl-theme-base) !important;
          }
        }
      }
      .tl-task-item__delete {
        &:hover {
          background-color: #ffd6d6;
          .tl-task-item__delete-icon {
            .stroke-shape-delete {
              stroke: var(--tl-theme-dark-red) !important;
            }
          }
        }
      }
    }
    .tl-task-item__task-name {
      font-weight: 500;
      font-family: "Inter Display";
      max-width: 80%;
      color: var(--tl-text-base);
      &.task-completed {
        text-decoration: line-through;
      }
    }
  }

  .tl-task-item__task {
    border-bottom: 1px solid var(--tl-theme-border);
    flex-grow: 1;

    .task-completed {
      color: var(--tl-text-light-2);
    }
  }
  .tl-task-item__due-date {
    color: var(--tl-theme-base);
    font-size: 0.875rem;
  }
  .tl-task-item__info {
    display: flex;
    gap: var(--tl-spacing-s);
  }
}

@media screen and (max-width: 1024px) {
  .tl-task-item {
    .tl-task-item__task-name {
      flex-grow: 1;
    }
  }
}
