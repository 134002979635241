.tl-filters {
  margin: var(--tl-spacing-l) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tl-filters__btn {
    button {
      font-size: 1rem;
      align-items: center;
    }
    &.tl-filters--active {
      background-color: var(--tl-theme-bg);
    }
  }
  button {
    border: none;
    padding: 0;
    background: none;
    display: flex;
    gap: var(--tl-spacing-xs);
    color: var(--tl-text-light-2);
    font-weight: 500;
    font-family: "Inter Display";
    letter-spacing: 0.2px;
  }
  .tl-filters__options {
    display: flex;
    gap: var(--tl-spacing-s);
    align-items: center;
    flex-wrap: wrap;
    .tl-filters__status-button {
      cursor: pointer;
      display: flex;
      gap: var(--tl-spacing-2xs);
      padding: var(--tl-spacing-2xs);
      background-color: var(--tl-theme-bg);
      font-size: 1rem;
      font-weight: 500;
      .tl-filters__btn-pending,
      .tl-filters__btn-completed {
        display: flex;
        align-items: center;
        gap: var(--tl-spacing-2xs);
        color: var(--tl-text-base);
        border-radius: 6px;
        span {
          padding: var(--tl-spacing-1xs) var(--tl-spacing-2xs);
          font-weight: 600;
          font-family: "Inter Display";
        }
      }
      .tl-filters__btn-pending {
        span {
          color: #eb6025;
        }
      }
      .tl-filters__btn-completed {
        span {
          color: #31b71b;
        }
      }
      .tl-filters--active {
        background-color: #fff;
        box-shadow: 0px 1px 2px 0px #e5e7eb;
      }
    }
    .tl-filters__tags,
    .tl-filters__status-button,
    .tl-filters__btn,
    .tl-filters__date {
      border-radius: var(--tl-spacing-xs);
      cursor: pointer;
    }

    .tl-filters__btn {
      &:hover {
        background-color: var(--tl-theme-bg);
      }
    }
  }
  .tl-filters__wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--tl-spacing-2xs);

    .tl-filters__wrapper-search {
      display: flex;
      align-items: center;
      gap: var(--tl-spacing-xs);
      .tl__search {
        .tl__search-container {
          padding: 0;
        }
        width: 100%;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid var(--tl-theme-border);
        padding: var(--tl-spacing-2xs) 0;
      }
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
    .tl-filters__wrapper-tags {
      display: flex;
      align-items: center;
      gap: var(--tl-spacing-xs);
      padding: var(--tl-spacing-2xs) 0;
      min-height: 2.375rem;
    }
  }
}

.filters-padding {
  padding: var(--tl-spacing-xs) var(--tl-spacing-s);
}
