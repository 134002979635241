.content-container {
  padding: var(--tl-spacing-l) var(--tl-spacing-2xl);
  .content-info-container {
    padding-top: var(--tl-spacing-md);
    padding-bottom: var(--tl-spacing-md);
    display: flex;
    flex-direction: column;
    gap: var(--tl-spacing-2xs);
    .content-info__section-heading {
      font-size: 2.25rem;
      font-weight: 600;
      font-family: "Inter Display";
      color: var(--tl-text-base);
    }
    .content-info__section-info {
      font-size: 1.125rem;
      color: var(--tl-text-light-2);
    }
  }
}
