.main-content {
  // display: grid;
  // grid-template-columns: 240px 1fr;
  // gap: 20px;
  @media screen and (min-width: 1024px) {
    margin-left: 16.25rem;
  }

  .content-wrapper {
    background-color: var(--white);
    // padding: 0 var(--tl-spacing-xl);
    border-radius: 12px;
    min-height: 100vh;
    box-shadow: 0px 1px 3px 0px var(--tl-theme-border);
  }

  .container {
    margin: 0;
    max-width: 100%;
  }

  .dashboard-section {
    margin: var(--tl-spacing-xl) 0;
  }
}

.dashboard-heading,
.dashboard-sub-heading {
  font-weight: 600;
}

.dashboard-heading {
  font-size: var(--tl-spacing-xl);
}

.dashboard-sub-heading {
  font-size: var(--tl-spacing-l);
}
