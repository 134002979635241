:root {
  --primary-color: #3d56fb;
  --black: #232020;
  --white: #fff;
  --gradient: 50;
  --shadow: 0px 3px 10px 0px var(--light-blue);
  --form-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  --highlight-shadow: 0px 3px 10px 0px var(--light-blue);
  --radius: 12px;
  --grey: #ebebeb;
  --grey-2: #eeedeb;
  --primary-hover: #3145c9;
  --secondary-color: #ebebeb;
  --primary-text-color: #333333;
  --padding-0: 0.5rem;
  --padding-1: var(--tl-spacing-md);
  --padding-2: var(--tl-spacing-xl);
  --padding-3: 3rem;
  --border-color: #ebebeb;
  --light-blue: #eceeff;

  /* New Vars */
  --tl-spacing-1xs: 0.125rem;
  /* 2px */
  --tl-spacing-2xs: 0.25rem;
  /* 4px */
  --tl-spacing-xs: 0.5rem;
  /* 8px */
  --tl-spacing-s: 0.75rem;
  /* 12px */
  --tl-spacing-md: 1rem;
  /* 16px */
  --tl-spacing-l: 1.25rem;
  /* 20px */
  --tl-spacing-xl: 1.5rem;
  /* 24px */
  --tl-spacing-2xl: 2rem;
  /* 32px */
  --tl-spacing-3xl: 2.25rem;
  /*36px */
  --tl-neutral-white: #ffffff;
  --tl-text-base: #1f2937;
  --tl-theme-base: #2563eb;
  --tl-theme-bg: #f3f4f6;
  --tl-theme-border: #e5e7eb;
  --tl-text-light-1: #374151;
  --tl-text-light-2: #6b7280;
  --tl-text-light-3: #9ca3af;
  --tl-text-light-4: #bfdbfe;
  --tl-theme-light-5: #e5f0ff;
  --tl-theme-dark-red: #df3131;
  --tl-theme-dark: #1d4ed8;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*------------------------------
     FONT FAMILY     
 ------------------------------- */
@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Regular.woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter Display";
  src: url("../assets/fonts/Inter-Regular.woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Inter Display";
  src: url("../assets/fonts/Inter-Medium.woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter Display";
  src: url("../assets/fonts/InterDisplay-SemiBold.woff2");
  font-weight: 600;
  font-style: normal;
}

/*------------------------------
     GLOBALS    
 ------------------------------- */

body {
  font-family: "Inter";
  font-size: var(--tl-spacing-md);
  color: var(--primary-text-color);
  letter-spacing: -0.2px;
  overflow-x: hidden;
  background-color: var(--tl-theme-bg);
  line-height: 1.4;
}

body.modal-open {
  overflow-y: auto !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  letter-spacing: -0.7px;
}

a {
  text-decoration: none;
  color: var(--tl-neutral-white);
  font-weight: 600;
}
a:hover {
  color: var(--tl-neutral-white);
}

p {
  margin-bottom: 0;
}

.tl-main {
  background-color: var(--tl-theme-bg);
  padding: var(--tl-spacing-2xl);
}

@media screen and (max-width: 1024px) {
  .tl-main {
    padding: 0 var(--tl-spacing-xs);
  }
}

.tl-home {
  padding: 0;
  background-color: var(--tl-neutral-white);
}

.tl-auth {
  height: 100vh;
}

.tl-padding {
  padding: var(--tl-spacing-s) var(--tl-spacing-md);
}

.tl-heading {
  font-weight: 600;
  font-family: "inter display";
  font-size: 1.125rem;
}

.grey-out {
  color: var(--tl-text-light-2);
}

input,
textarea {
  border: none;
  outline: none;
  width: 100%;
  padding: 0;
  font-size: 1rem;
}

textarea {
  resize: none;
}

button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 1rem;
}

.tl__focused {
  box-shadow: 0 0 0 3px #e5f0ff;
  border-radius: var(--tl-spacing-xs);
}

/*------------------------------
     BUTTONS
 ------------------------------- */
.btn--primary {
  background-color: var(--primary-color);
  color: var(--white);
  font-size: var(--tl-spacing-md);
  border: none;
  font-weight: 600;
}

.btn--primary:hover {
  background-color: var(--primary-hover);
  color: var(--white);
}

.btn--secondary {
  background-color: var(--secondary-color);
  color: var(--black);
  font-size: var(--tl-spacing-md);
  border: none;
  font-weight: 600;
}

.btn--secondary:hover {
  background-color: var(--white);
  color: var(--black);
}

.btn--delete {
  border: none;
}

.tl-btn {
  border-radius: var(--tl-spacing-xs);
  padding: var(--tl-spacing-s) var(--tl-spacing-md);
  font-size: var(--tl-spacing-md);
  font-weight: 500;
  font-family: "Inter Display";
  cursor: pointer;
}

.tl-btn--primary {
  background-color: var(--tl-theme-base);
  color: var(--tl-neutral-white);
  border: 1px solid var(--tl-theme-base);
  box-shadow: 0px 1px 2px 0px #93c5fd;
  &:hover {
    background-color: var(--tl-theme-dark);
  }
}
.tl-btn--secondary {
  background-color: var(--tl-neutral-white);
  color: var(--tl-text-light-1);
  border: 1px solid var(--tl-theme-border);
  &:hover {
    background-color: var(--tl-theme-border);
  }
}

.tl-btn__delete {
  background-color: var(--tl-theme-dark-red);
  color: var(--tl-neutral-white);
  border: 1px solid var(--tl-theme-dark-red);
  &:hover {
    background-color: #ca2828;
  }
}

/*------------------------------
     FORM STYLES
 ------------------------------- */
.tl-form {
  width: 30%;
  padding: 1.5rem;
  margin: 1.5rem auto;
  box-shadow: var(--form-shadow);
  border-radius: var(--radius);

  h4 {
    letter-spacing: -0.9px;
    font-weight: 600;
    font-family: "Inter Display";
    font-size: var(--tl-spacing-2xl);
  }

  p {
    a {
      color: var(--primary-color);
    }
  }

  .tl-form__field {
    margin: var(--tl-spacing-md) 0;
    .tl-form__field-label {
      margin-bottom: 0.5rem;
      display: inline-block;
      font-family: "Inter Display";
    }

    .tl-form__field-input {
      padding: 0.5rem;
      border: 2px solid var(--tl-theme-border);
      border-radius: 4px;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 80%;
  }
}

.form-background {
  box-shadow: var(--form-shadow);
  padding: var(--tl-spacing-xl);
  border-radius: var(--radius);
  background-color: var(--white);
}

.form-background h4 {
  letter-spacing: -0.9px;
}

.login-form,
.custom-form,
.register-form {
  margin: 0 auto;
}

.login-form input:focus,
.custom-form input:focus,
.register-form input:focus {
  box-shadow: 0 0 0 var(--tl-spacing-2xs) rgb(41 64 211 / 25%);
}

.count-text {
  font-size: 0.8rem;
}

.count-text-danger {
  color: tomato;
  font-weight: 600;
}

/*------------------------------
     React-select styles
 ------------------------------- */
.custom-form .react-select__control {
  border-radius: 0.375rem;
}

.custom-form .react-select__multi-value {
  background-color: var(--grey);
}

/*------------------------------
     React-Datepicker styles
 ------------------------------- */
.react-datepicker__close-icon::after {
  content: "X";
  width: var(--tl-spacing-md);
  height: var(--tl-spacing-md);
  background-color: var(--tl-theme-base);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*------------------------------
    ICON STYLES
 ------------------------------- */
.logout-icon,
.list-icon {
  margin-right: 0.8rem;
}

.close-icon:hover {
  cursor: pointer;
}

/*------------------------------
    TASK STATUS STYLES
 ------------------------------- */
.completed {
  background-color: #edffea;
  color: #31b71b;
}

.pending {
  background-color: #ffede5;
  color: #eb6025;
}

.total {
  background-color: var(--tl-theme-bg);
  color: var(--tl-text-light-1);
}

.productivity {
  background-color: var(--tl-theme-light-5);
  color: var(--tl-theme-base);
}

/*------------------------------
     GLOBAL CLASSES
 ------------------------------- */
.tl-border {
  border: 1px solid var(--tl-theme-border);
}

.border-bottom {
  border-bottom: 1px solid var(--border-color);
}

.delete-icon {
  cursor: pointer;
}

.sidebar {
  margin-top: 66px;
  background-color: var(--black);
}

/* Navabr and Dashboard */
.modal-open {
  padding-right: 0 !important;
}

/* Logout Button */
.logout-btn {
  margin: var(--tl-spacing-md) 0;
}

/* Toast Styles */

.error-toast {
  background-color: #f8d7da;
  color: #721c24;
}

.success-toast {
  background-color: #f0fdf4;
  color: #16656c;
}

/*------------------------------
    toastify Styles
 ------------------------------- */

.Toastify__close-button {
  display: none !important;
  /* Hide the close button */
}

.Toastify__toast-container {
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
  z-index: 9999;
}

/*------------------------------
    Overlay Styles
 ------------------------------- */
.tl-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--tl-theme-bg);
  z-index: 1000;
}
