.tl-task__form-wrapper {
  display: flex;
  flex-direction: column;
  .tl-task__form-container {
    display: flex;
    flex-direction: column;
    gap: var(--tl-spacing-2xl);
  }
  .tl-task__form {
    // flex-grow: 1;
    .tl-task__form-name-desc {
      padding: var(--tl-spacing-md);
      border-radius: var(--tl-spacing-s);
      display: flex;
      flex-direction: column;
      gap: var(--tl-spacing-xs);
      // input {
      //   color: var(--tl-text-light-3);
      // }
      .tl-task__form-task-name {
        input {
          font-size: 1.125rem;
          font-weight: 500;
          font-family: "Inter Display";
        }
      }
      .tl-task__form-task-desc {
        input {
          font-size: 1rem;
          font-weight: 400;
          font-family: "Inter Display";
        }
      }
      &.view-mode {
        padding: 0;
      }
    }
    .tl-task__form-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: var(--tl-spacing-xs);

      .tl-task__form-task-status {
        display: flex;
        align-items: center;
        gap: var(--tl-spacing-xs);
        padding: var(--tl-spacing-2xs) 0;
      }

      .tl-task__form-task-done,
      .tl-task__form-task-pending {
        display: flex;
        gap: var(--tl-spacing-xs);
        align-items: center;
        .tl-task__form-task-status-text {
          display: inline-block;
          width: 100%;
          border-bottom: 1px solid var(--tl-theme-border);
        }
      }
    }
    .tl-task__form-task-name--view {
      font-size: 1.125rem;
      font-weight: 500;
      font-family: "Inter Display";
      color: var(--tl-text-base);
      letter-spacing: 1.2;
    }
    .tl-task__form-task-desc--view {
      font-size: var(--tl-spacing-md);
      font-weight: 400;
      line-height: 1.4;
      letter-spacing: -0.2;
      color: var(--tl-text-light-2);
    }
  }

  .tl-task__form-tags-wrapper {
    display: flex;
    gap: var(--tl-spacing-xs);
    padding: var(--tl-spacing-2xs) 0;
    align-items: center;
  }
}

@media screen and (min-width: 1024px) {
  .tl-task__form-wrapper {
    .tl-task__form {
      flex-grow: 1;
    }
    .tl-task__form-submit {
      flex-direction: row;

      .tl-task__form-cancel,
      .tl-task__form-save {
        width: 50%;
      }
    }
  }
}
