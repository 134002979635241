.tl-home__wrapper {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(147, 197, 253, 1) 100%);
  padding-bottom: 0 !important;
}
.tl-wrapper {
  max-width: 1264px;
  margin: 0 auto;
}

.tl-section {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
